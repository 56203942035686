import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { getMinRes, getScaledResolutions } from "./utilts";

interface Props {
  image: string;
  imageChanged: (image: {
    src: string,
    width: number,
    height: number,
  }) => void;
}

interface State {
  isDrawing: boolean;
  color: string;
  strokes: any[];
}

const DrawCard: React.FC<Props> = ({ image, imageChanged }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [state, setState] = useState<State>({
    isDrawing: false,
    color: "#000000",
    strokes: []
  });

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
          const browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

          const dimi = browserWidth > 768 ? 512 : 256;

          const scaledDimensions = getScaledResolutions(img.width, img.height, dimi, browserWidth, dimi);
          const minRes = getMinRes(scaledDimensions.width, scaledDimensions.height);
          canvas.width = scaledDimensions.width;
          canvas.height = scaledDimensions.height;
          ctx.drawImage(img, 0, 0, scaledDimensions.width, scaledDimensions.height);
          imageChanged({
            src: canvas?.toDataURL() || "",
            width: Math.round(minRes.width),
            height: Math.round(minRes.height),
          });
          setState({
            ...state,
            strokes: [canvas?.toDataURL()]
          });
        };
        img.src = image;
      }
    }
  }, [image]);



  const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    setState({
      ...state,
      isDrawing: true,
    });
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (ctx) {
      ctx.beginPath();
      ctx.moveTo(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
    }
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (state.isDrawing) {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");

      if (ctx) {
        ctx.strokeStyle = state.color;
        ctx.lineWidth = 5;
        ctx.lineCap = "round";
        ctx.lineTo(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
        ctx.stroke();
      }
    }
  };

  const handleMouseUp = () => {
    setState({
      ...state,
      isDrawing: false,
      strokes: [...state.strokes, canvasRef.current?.toDataURL()]
    });

    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas?.getContext("2d");
    imageChanged({
      src: canvas?.toDataURL() || "",
      width: canvas.width,
      height: canvas.height,
    });
  };

  const handleUndo = () => {
    setState({
      ...state,
      strokes: state.strokes.slice(0, -1),
    });

    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas?.getContext("2d");
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (let i = 0; i < state.strokes.length - 1; i++) {
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
      img.src = state.strokes[i];
    }

    setTimeout(() => {
      const src = canvas?.toDataURL() || "";
      imageChanged({
        src,
        width: canvas.width,
        height: canvas.height,
      });
    }, 500);
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      color: event.target.value,
    });
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
    event.preventDefault();
    setState({
      ...state,
      isDrawing: true,
    });
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas) return;

    if (ctx) {
      ctx.beginPath();
      ctx.moveTo(event.touches[0].pageX - canvas?.offsetLeft || 0, event.touches[0].pageY - canvas?.offsetTop || 0);
    }
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
    event.preventDefault();
    if (state.isDrawing) {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");
      if (!canvas) return;

      if (ctx) {
        ctx.strokeStyle = state.color;
        ctx.lineWidth = 5;
        ctx.lineCap = "round";
        ctx.lineTo(event.touches[0].pageX - canvas?.offsetLeft || 0, event.touches[0].pageY - canvas?.offsetTop || 0);
        ctx.stroke();
      }
    }
  };

  const handleTouchEnd = (event: React.TouchEvent<HTMLCanvasElement>) => {
    event.preventDefault();
    setState({
      ...state,
      isDrawing: false,
      strokes: [...state.strokes, canvasRef.current?.toDataURL()]
    });

    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas?.getContext("2d");
    imageChanged({
      src: canvas?.toDataURL() || "",
      width: canvas.width,
      height: canvas.height,
    });
  };

  return (
    <Card>
      <Canvas
        ref={canvasRef}
      // onMouseDown={handleMouseDown}
      // onMouseMove={handleMouseMove}
      // onMouseUp={handleMouseUp}
      // onTouchStart={handleTouchStart}
      // onTouchMove={handleTouchMove}
      // onTouchEnd={handleTouchEnd}
      // onTouchCancel={handleTouchEnd}
      />
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <ColorPickerContainer>
          <label>Color:</label>
          <ColorPicker
            type="color"
            value={state.color}
            onChange={handleColorChange}
          />
        </ColorPickerContainer>
        <UndoButton onClick={handleUndo}>
          <Icon.ArrowCounterclockwise
            title="Undo"
            style={{
              verticalAlign: 'text-bottom',
              marginRight: '0.25rem',
            }}
            size={20}
          />
        </UndoButton>
      </div> */}
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Canvas = styled.canvas`
  touch-action: none;
  max-width: 100%;
`;

const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const ColorPicker = styled.input`
  margin-left: 10px;
`;
const UndoButton = styled.div`
  margin-top: 20px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-left:1rem;

`;

export default DrawCard;
