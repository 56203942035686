export class MyAssetsState {
    private account: any = null;
    private assets: any[] = [];

    public getAccount(): any {
        return this.account;
    }

    public setAccount(account: any): void {
        this.account = account;
    }

    public get(): any[] {
        return this.assets;
    }

    public setList(assets: any[]): void {
        this.assets = assets;
    }

    public addassets(assets: any): void {
        assets.id = this.assets.length + Date.now();
        this.assets.unshift(assets);

        localStorage.setItem('myAssets', JSON.stringify(this.assets));
    }

    public removeassets(geb: any): void {
        this.assets = this.assets.filter((assets) => assets !== geb);
    }
}