import { Step } from './../App';
export const SHIBA_HEROS = "Rj6ecAQISUGg9LV6Uo6XdQ";
export const ANIME_TOEI = "YMB8PiLvSkK7EI_tGSVfRg";
export const ANIME_16BIT = "I_1MM3GTSASxbuNorl8Hbw";
export const PIXEL_CUTE = "N3wtFXgCRf2sVNU6r2KRlA";
export const SHIBOSHI = "eZWEVMD7R5aogJ9p8WfZAw";

export const PIXEL_GOOD = "S0p6qvYBSIugYXW5jm4DTg";

export const ANIME_JID = "Eng8wN5SR0yyJBhdpoGSeQ";
export const BIT_HDR_PIXEL = "IfazZpuLQVyCzeuymJS-fQ";
export const BIT_HDR_CHARACTER = "bt4lK0PaQjeI6n96-GL63w";
export const REALISTIC_ANIME = "UIzZuC52SraZ6n3JX8mOlQ";
export const GHIBLI_CHARACTER = "N7iGEj9vQRGxOKmnkB_kUA";
export const GHIBLI = "AV1DusGhTYeO6oWwYaLKLQ";

export const GENERAL_PIXEL = "S0p6qvYBSIugYXW5jm4DTg";

export const PB_PAPER_WORLD_ISO = "S5o6HLv9Qkau8oUUrJQp5g"; // Isometric Paper World v2 by Araminta
export const PB_PAINTED_ISO = "eC4TkBw5R0mUoQthqZR8GA"
export const PB_ANIME_CATCHER = "PNqixjKURbiouk49_gYWCw"


export function getModelsToStep() {
    return [PIXEL_GOOD, GHIBLI];
}