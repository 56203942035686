import React, { useEffect, useRef, useState } from 'react'
import './App.css'
import styled from 'styled-components'
import { ScenarioClient } from './scenarioGG/scenario.client'
import GeneratorPanel, { generateNew, generateNewListener } from './generator-panel'
import { ScenarioAPI } from './scenarioGG/scenario.api'
import { Generations } from './generations.state'
import { MyAssetsState } from './my-assets.state'
import { ModelsState } from './model.state'
import SingleGenerationPane from "./single-generation-pane"
import * as Icon from 'react-bootstrap-icons'
import ResultImagePanel from "./result-image-panel"
import { handleRegenerate, urlToBase64 } from "./utilts"

export interface Step {
  type?: '2D' | '3D' | null
  topic?: 'isometric' | 'sidescroll' | null
  subject?: 'character' | 'object' | null
}

// const scenarioAPI = new ScenarioAPI(apiKey);
// const scenarioAPI = new ScenarioClient("http://v22017105055054583.bestsrv.de:3400/api");
// export const scenarioAPI = new ScenarioClient("http://localhost:3400/api");
export const scenarioAPI = new ScenarioClient('https://api.eww.app/sc')
export const scenarioLocalAPI = new ScenarioAPI()
export let generations = new Generations()
export let assetsState = new MyAssetsState()
export const modelState = new ModelsState()
function App() {
  const [isDiscoverVisible, setIsDiscoverVisible] = useState<boolean>(false)
  const [generationState, setGenerationState] = useState<"none" | "generating" | "finished">("none")
  const [generationData, setGenerationData] = useState<any>(null)
  const [myImagesVisible, setMyImagesVisible] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (localStorage.getItem('myAssets')) {
      assetsState.setList(JSON.parse(localStorage.getItem('myAssets') || '[]'))
    }

    generateNewListener((data: any) => {
      setMyImagesVisible(false)
      setGenerationState("generating");
      setGenerationData(data);
    });

  }, [])

  useEffect(() => {
    if (containerRef.current) {
      document.body.style.overflow = isDiscoverVisible ? 'hidden' : 'auto'
      containerRef.current.style.overflow = isDiscoverVisible
        ? 'hidden'
        : 'auto'
    }
  }, [isDiscoverVisible])

  const genNew = () => {
    setGenerationState("none")
    setGenerationData(null)
  }

  const generationMemo = React.useMemo(() => {

    if (!generationData) {
      return <></>
    }

    return <SingleGenerationPane data={generationData} genNew={genNew} />
  }, [generationData])

  const AppContent = () => {
    switch (generationState) {
      case 'none':
        return <GeneratorPanel />
      case 'generating':
        return <></>
      case 'finished':
        return <div> finished </div>
    }
  }

  const appContentMemo = React.useMemo(() => {
    return <AppContent />
  }, [generationState])

  const myImages = JSON.parse(localStorage.getItem('myAssets') || '[]');


  const ResultPanel: React.FC<any> = ({ data }) => {
    if (!data) {
      return <></>;
    }

    const inFdata = data.inference;

    return (
      <>
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {inFdata.images.map((image: any, index: number) => {
                return <div className="p-2">
                  <ResultImagePanel
                    key={index}
                    data={data.inference}
                    footerVisible={inFdata.status === "succeeded"}
                    image={image}
                    onRegenerate={() => handleRegenerate(image.url, inFdata)}
                  />
                </div>
              })}
            </div>
          </div>
        </div >
      </>
    );
  }

  return (
    <>
      <div className="top-right-icon">
        <Icon.ClockHistory className="pointer" onClick={() => setMyImagesVisible(true)}></Icon.ClockHistory>
      </div>
      {myImagesVisible && <>

        <div className="modal-ext">
          <div className="card card-register" style={{ maxWidth: "750px" }}>
            <div className="closei-btn" onClick={() => setMyImagesVisible(false)}>X</div>
            <div className="card-body text-center d-flex justify-content-center align-items-center" style={{ flexWrap: "wrap", flexDirection: "column" }}>
              <h4>My history</h4>
              <div className="myimgpan">
                {myImages.map((image: any, index: number) => <ResultPanel key={index} data={image} />)}
              </div>
            </div>

            <div className="d-flex justify-content-center pb-2">
              <button className="btn btn-secondary mr-2" onClick={() => setMyImagesVisible(false)}>
                Ok
              </button>
            </div>
          </div>
        </div>

      </>}
      <div ref={containerRef} className="App" style={{}}>
        {appContentMemo}
        {generationMemo}
      </div>
    </>
  )
}

export default App

export const PoweredPanel = styled.div`
  padding: 0.5rem;
  text-align: center;
  color: lightgray;
  background: #333333;
  text-align: center;
  padding-bottom: 1.5rem;
`