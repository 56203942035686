import { Step } from './../App';
import { InferenceParameters } from "../scenarioGG/models/inference-parameters.model";
import { GENERAL_PIXEL, GHIBLI, GHIBLI_CHARACTER, REALISTIC_ANIME, BIT_HDR_CHARACTER, ANIME_16BIT, SHIBA_HEROS, ANIME_TOEI, PB_PAPER_WORLD_ISO, PB_ANIME_CATCHER, PB_PAINTED_ISO, PIXEL_CUTE, SHIBOSHI, PIXEL_GOOD } from './defined-models';


export function getParametersToTopic(step: Step) {
    let prompt = "";

    if (step.subject == "character" && step.topic == "isometric") {
        prompt = "isometric view, isometric game character sprite, "
    }

    if (step.subject == "character" && step.topic == "sidescroll") {
        prompt = "sidescroll view, sidescroll game character sprite, "
    }

    if (step.subject == "object" && step.topic == "isometric") {
        prompt = "isometric view, isometric game asset sprite object, "
    }

    if (step.subject == "object" && step.topic == "sidescroll") {
        prompt = "sidescroll view, sidescroll game asset sprite object, "
    }

    return prompt;
}

export function getParametersToModel(model: string): Partial<InferenceParameters> {
    switch (model) {

        case PIXEL_GOOD:
            return {
                strength: 0.64,
                prompt: "as game asset",
                guidance: 9,
            }

        case SHIBA_HEROS:
            return {
                strength: 0.72,
                prompt: "shiba inu, cartoon style",
                guidance: 16,
                negativePrompt: "no shiba inu",
            }

        case ANIME_TOEI:
            return {
                strength: 0.66,
                prompt: "shounen anime style, lively colors",
                guidance: 16,
                negativePrompt: "no anime style",
            }

        case ANIME_16BIT:
            return {
                strength: 0.66,
                prompt: "detailed 16-bit art style, style of owlboy pixel art",
                negativePrompt: "not 16-bit art style",
                guidance: 16
            }

        case PIXEL_CUTE:
            return {
                strength: 0.66,
                prompt: "cute pixel art style",
                negativePrompt: "",
                guidance: 16
            }

        case SHIBOSHI:
            return {
                strength: 0.66,
                prompt: "detailed shiba inu character, style of shiboshi NFT pixel art",
                negativePrompt: "",
                guidance: 16
            }

        case GHIBLI_CHARACTER:
            return {
                strength: 0.66,
                prompt: "studio ghibli style",
                guidance: 16
            }

        case BIT_HDR_CHARACTER:
            return {
                strength: 0.66,
                prompt: "detailed 16-bit art style, style of owlboy pixel art",
                guidance: 16
            }

        case PB_PAPER_WORLD_ISO:
            return {
                strength: 0.66,
                prompt: "paper world arty style",
                guidance: 16
            }


        case REALISTIC_ANIME:
            return {
                strength: 0.70,
                prompt: "{best quality}, {highres}, high detailed 2.5D isometric character, {{{fullbody}}}, smooth, anime style",
                guidance: 20,
                negativePrompt: "lowres, polar lowres, low contrast, bad anatomy, bad face, bad hands, bad body, bad feet, bad proportions, {bad leg}, {more legs}, worst quality, bad eyes, low quality, normal quality, gross proportions, blurry, poorly drawn, text,error, missing fingers, missing arms, missing legs, short legs, extra digit,  2girls, indoors, out of frame, only_upper body, only_lower body, [petite], low background, distorted perspective, {{{{{{{colored background}}}}}}}, {{{out of frame}}}"
            }

        case GHIBLI:
            return {
                strength: 0.63,
                prompt: "as game asset",
                guidance: 9
            }


        case GENERAL_PIXEL:
            return {
                strength: 0.25,
                prompt: "Isometric pixel asset, anime style",
            }


        default:
            return {
                strength: 0.61,
                prompt: "",
                guidance: 11
            }
    }
}
