import { useEffect, useRef, useState } from "react";
import './App.css';
import { ImageResult, ImageSelector } from "./Image-selector";
import { ModelResponse } from "./scenarioGG/models/model-response.model";
import styled from "styled-components";
import { InferenceParameters } from "./scenarioGG/models/inference-parameters.model";
import { getParametersToModel, getParametersToTopic } from "./definitions/pre-defined-parameters";
import ParametersPanel from "./parameters-panel";
import { PIXEL_GOOD, getModelsToStep } from "./definitions/defined-models";
import { Step, modelState, scenarioAPI, scenarioLocalAPI } from "./App";
import * as Icon from 'react-bootstrap-icons';
import { getProportions } from "./utilts";

let strength: number | undefined = undefined;
let modelsState: ModelResponse[] = [];
const GeneratorPanel: React.FC = () => {
    const [models, setModels] = useState<ModelResponse[]>(modelsState);
    const [state, setState] = useState<{ params: InferenceParameters, modelId: string | undefined } | null>({
        modelId: PIXEL_GOOD,
        params: {
            image: undefined,
            ...getParametersToModel(PIXEL_GOOD)
        }
    });
    const [showParameters, setShowParameters] = useState<boolean>(false);
    const [prompt, setPrompt] = useState<string>("");
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        if (models.length > 0) {
            return;
        }

        const allowedModels = getModelsToStep();
        scenarioAPI.getModels().then((models) => {
            const allModels = [...models];
            const filtered = allModels.filter((model) => allowedModels.includes(model.id));
            setModels([...filtered]);
            modelState.addModels([...models]);

            modelsState = [...filtered];
        });

    }, []);

    async function generateAsset(event: MouseEvent) {
        event.preventDefault();
        if (!state || !state.modelId) {
            return;
        }

        let finalPrompt = (prompt ?? "") + state?.params?.prompt ?? "";

        const preDefinedParams = getParametersToModel(state.modelId);
        finalPrompt = finalPrompt + " " + preDefinedParams.prompt;

        const param = {
            ...state?.params,
            prompt: finalPrompt,
            image: state.params.image,
            width: state?.params?.width ?? 512,
            height: state?.params?.height ?? 612
        }

        // round width and height to non decimal
        param.width = Math.round(param.width);
        param.height = Math.round(param.height);

        generateNew({ modelId: state?.modelId, param })
    }

    const onSelectImage = async (image: ImageResult | null, modelId: string | undefined, template: { src: string, prompts: string } | null) => {
        if (!modelId || !image || !template) {
            return;
        }

        const prompt = template.prompts ?? "";
        // let negativePrompt = "";

        // if (prompt.includes("negative")) {
        //     negativePrompt = getNegativePrompts("object");
        // }

        // if (prompt.includes("character")) {
        //     negativePrompt = getNegativePrompts("character");
        // }

        const reso = await getProportions(image.src);
        const modelIdChanged = state?.modelId !== modelId;

        setState({
            params: {
                ...state?.params,
                image: image.src,
                width: reso.width,
                height: reso.height,
                numInferenceSteps: 70,
                prompt,
                ...(modelIdChanged ? getParametersToModel(modelId ?? state?.modelId ?? PIXEL_GOOD) : undefined),
            },
            modelId
        });
    }

    const onToggleModel = (model: any) => {
        setState({
            ...state,
            modelId: model?.id ?? 0
        } as any);
    };

    const onChangeParameters = (params: Partial<InferenceParameters>) => {
        if (!state) {
            return;
        }


        strength = params.strength;

        setState({
            ...state,
            params: {
                ...state.params,
                ...params
            },
            strength: params.strength
        } as any);
    }

    const onChangeStrength = (_strength: number) => {
        if (!state) {
            return;
        }
        state.params.strength = _strength;
        strength = _strength
    }


    function GeneratorButtonPanel() {
        return <>
            <button disabled={!state?.params.image} className="btn btn-primary btn-round btn-lg mt-2" onClick={(e: any) => generateAsset(e)}>
                Generate asset
            </button>
        </>
    }

    return (
        <div ref={containerRef} className="App" style={{
            overflowX: "hidden",
            overflowY: "auto",
        }}>
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100svh" }}>

                {models.length <= 0 && <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100svh" }}>
                    <h1 className="text-center">Loading...</h1>
                </div>}


                {models.length > 0 && <>
                    <div style={{ minWidth: "350px", marginTop: "-5%" }}>
                        <div className="d-flex justify-content-center align-items-center" style={{ flexWrap: "wrap" }}>
                            <div style={{ minWidth: "350px" }}>
                                <ImageSelector
                                    setModel={onToggleModel}
                                    strength={state?.params.strength ?? null}
                                    models={models} selectImage={onSelectImage}
                                    setPrompt={setPrompt}
                                    onChangeStrength={onChangeStrength}
                                />
                            </div>
                            <div className="ml-md-4 text-center">
                                <GeneratorButtonPanel />
                                <br />
                                {models && <div className="my-2 mt-4 badge badge-default" style={{ cursor: "pointer" }} onClick={() => setShowParameters(!showParameters)}>Advanced settings
                                    <Icon.Pencil className="ml-2"></Icon.Pencil>
                                </div>}
                            </div>
                        </div>
                        {models && showParameters && <div style={{ textAlign: "center", display: "flex", justifyContent: "center", width: "100%" }}> <ParametersPanel close={() => setShowParameters(false)} onParameterChange={onChangeParameters} parameters={state?.params!} /> </div>}
                    </div>
                </>}
            </div>

        </div >

    );
}

export default GeneratorPanel;

export const PoweredPanel = styled.div`
padding: 0.5rem;
    text-align: center;
    color: lightgray;
    background: #333333;
    text-align: center;
    padding-bottom: 1.5rem;
`;


export let generateNew: any = null;
export function generateNewListener(callback: any) {
    generateNew = callback;
}