
import React, { useState, useEffect } from "react";
import { assetsState, scenarioAPI } from "./App";
import ResultImagePanel from "./result-image-panel";
import 'swiper/css';
import { InferenceResponse } from "./scenarioGG/models/inference-response.model";
import { handleRegenerate } from "./utilts";
const retries = 4;
let generting = false;
const SingleGenerationPane: React.FC<{ data: any, genNew: any }> = ({ data, genNew }) => {
    const [result, setResult] = useState<InferenceResponse | null>(null);
    const [generating, setGenerating] = useState<boolean>(true);
    const [retry, setRetry] = useState<number>(data.retry ?? 0);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            if (generting) {
                return;
            }

            generting = true;
            setGenerating(true);
            setResult(null);
            setError(false);
            generateAsset(data.modelId, data.param);
        }, 100);
    }, [data]);

    async function generateAsset(modelId: string, param: any) {
        if (!data.timer) {
            data.timer = setTimeout(() => {
                data.isGenerating = false;
                generting = false;
                data.error = true;
                setError(true);
                setGenerating(false);
                setRetry(-1);
            }, 90000);
        }
        scenarioAPI.createInferenceImage2Image(modelId, param).then((response) => {
            data.result = response;
            setResult(response);
            tryFetchAgain(response);
        }).catch((error) => {
            data.isGenerating = false;
            data.error = true;
            generting = false;
            setError(true);
            setGenerating(false);
            setRetry(-1);
        });
    }

    function addToMyAssets(response: any) {
        assetsState.addassets(response);
    }

    function tryFetchAgain(result: InferenceResponse) {
        scenarioAPI.getInferenceResult(result?.inference.modelId, result.inference.id).then((response) => {

            if (data.isFetching || data.error) {
                return;
            }

            data.isFetching = true;

            setResult(response);
            data.result = response;

            if (response.inference.status === "succeeded") {
                setGenerating(false);
                setRetry(0);
                addToMyAssets(response);
                data.isGenerating = false;
                data.succeed = true
                data.timer && clearTimeout(data.timer);
            }

            if ((response?.inference?.progress ?? -1) > 0) {
                if (data.timer) {
                    clearTimeout(data.timer);
                }
            }

            if (response.inference.status === "in-progress") {
                setTimeout(() => {
                    tryFetchAgain(response);
                }, 3000);
            }

        }).catch((error) => {
            console.log(error);
            setError(true);
            generting = false;
            if (data.timer) {
                clearTimeout(data.timer);
            }

            if (retry != retries) {
                setTimeout(() => {
                    const ret = retry + 1;
                    data.retry = ret;
                    setRetry(ret)
                    tryFetchAgain(result)
                }, 3000);
            } else {
                data.isGenerating = false;
                data.error = true;
                setRetry(-1);
            }
        }).finally(() => {
            data.isFetching = false;
            generting = false;
        });
    }

    function ResultPanel() {
        if (!result) {
            return <></>;
        }

        const inFdata = result.inference;

        return (
            <>
                {inFdata.status === "succeeded" && <p className="h4 mt-4 text-white">Select an image or <button className="btn btn-primary btn-sm" onClick={() => genNew()}>Generate a new</button></p>}
                {inFdata.status === "failed" && <>
                    <h3 className="mt-4">Error on generating asset </h3>
                    <button className="btn btn-primary btn-sm" onClick={() => genNew()}>Please try again</button>
                </>}
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {inFdata.images.map((image, index) => {
                        return <div className="p-2">
                            <ResultImagePanel
                                key={result.inference.id + index}
                                data={result.inference}
                                footerVisible={inFdata.status === "succeeded"}
                                image={image}
                                onRegenerate={() => handleRegenerate(image.url, inFdata)}
                            />
                        </div>
                    })}
                </div>
            </>
        );
    }

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "90svh" }}>
            <div>
                {generating && <>
                    <div className="position-absolute w-100" style={{ left: 0, top: 0 }}>
                        <p className="h2 text-primary mt-4 bold pt-4 text-bold gentext">Generating asset!</p>
                        <img src="/images/draw.gif" />
                    </div>
                </>
                }
                {error && <>    <h3 className="mt-4">Unusual error </h3>
                    <button className="btn btn-primary btn-sm" onClick={() => genNew()}>Please try again</button>
                </>}
                <ResultPanel />
            </div>
        </div>
    );
};

export default SingleGenerationPane;