import { ModelResponse } from './scenarioGG/models/model-response.model';
export class ModelsState {
    private models: ModelResponse[] = [];
    private fetchedOwnModels = false;

    public getModel(modelId: string): ModelResponse | undefined {
        return this.models.find((model) => model.id == modelId);
    }

    public addModels(models: ModelResponse[]): void {
        // we filter out same models
        this.models = this.models.filter((model) => !models.find((m) => m.id == model.id));
        this.models = [...models, ...this.models];
    }

    public get(): ModelResponse[] {
        return this.models;
    }

    public getModelName(modelId: string): string {
        const model = this.getModel(modelId);
        if (model) {
            return model.name;
        }

        return '-';
    }

    public hasFetchedOwnModels(): boolean {
        return this.fetchedOwnModels;
    }

    public setFetchedOwnModels(): void {
        this.fetchedOwnModels = true;
    }
}