import { create } from 'ipfs-http-client'
import { ScenarioBaseAPI } from './scenario-base';
import { InferencesOfModelResponse } from "./models/inference-model-response.model";
import { InferenceParameters } from './models/inference-parameters.model';
import { InferenceResponse, InferenceResponseParameters } from './models/inference-response.model';
import { ModelResponse } from './models/model-response.model';
import axios from 'axios';
import {Buffer} from 'buffer';

export class ScenarioClient implements ScenarioBaseAPI {
    private readonly baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async searchImg(searchstr: string): Promise<string[]> {
        const response = await axios.get<string[]>(`${this.baseUrl}/scenario/img/${searchstr}`);
        return response.data;
    }

    public async generateImg(prompt: string): Promise<any[]> {
        const response = await axios.post<any[]>(`${this.baseUrl}/scenario/imgGenStable/`, {
            prompt,
            numbers: 4,
            image_dimensions: "512x512",
            num_inference_steps: 60,
            guidance_scale: 15
        });
        return response.data;
    }

    public async generateImgDale(prompt: string): Promise<string[]> {
        const response = await axios.get<string[]>(`${this.baseUrl}/scenario/imgGen/${prompt}`);
        return response.data;
    }

    public async getModels(): Promise<ModelResponse[]> {
        const response = await axios.get<ModelResponse[]>(`${this.baseUrl}/scenario/models`);
        return response.data;
    }

    public async createInferenceText2Image(modelId: string, prompt: string): Promise<InferenceResponse> {
        const response = await axios.post<InferenceResponse>(`${this.baseUrl}/scenario/models/${modelId}/inferences/txt2img`, { prompt });
        return response.data;
    }

    public async createInferenceImage2Image(modelId: string, parameters: InferenceParameters | InferenceResponseParameters): Promise<InferenceResponse> {
        const response = await axios.post<InferenceResponse>(`${this.baseUrl}/scenario/models/${modelId}/inferences/img2img`, parameters);
        return response.data;
    }

    public async getInferenceResult(modelId: string, inferenceId: string): Promise<InferenceResponse> {
        const response = await axios.get<InferenceResponse>(`${this.baseUrl}/scenario/models/${modelId}/inferences/${inferenceId}`);
        return response.data;
    }

    public async getResultsOfModel(modelId: string, pageSize?: string, paginationToken?: string): Promise<InferencesOfModelResponse> {
        const url = `${this.baseUrl}/scenario/models/${modelId}/inferences`;
        const response = await axios.get<InferencesOfModelResponse>(url, { params: { pageSize, paginationToken } });
        return response.data;
    }

    public async googleAuth(cred: string): Promise<any> {
        const url = `${this.baseUrl}/scenario/googleAuth/${cred}`;
        const response = await axios.get<any>(url);
        return response.data;
    }

    async doRemoveBg(imgsd: any) {
        return new Promise(async (resolve, reject) => {
            try {

                const resImg = await this.removeBackground(imgsd)

                const image = new Image();
                image.src = resImg;

                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    const scaleFactor = image.width > 400 ? 400 / image.width : 1;

                    canvas.width = image.width * scaleFactor;
                    canvas.height = image.height * scaleFactor;

                    ctx!.drawImage(image, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob(async (blob) => {
                        const cid = await ScenarioClient.uploadToIpfs(blob as any);
                        resolve(`https://eww.infura-ipfs.io/ipfs/${cid}`)
                    }, "image/png");
                };
            } catch (e) {
                reject()
            }
        });
    }

    async removeBackground(bae64: any) {
        const response = await axios.post(`${this.baseUrl}/scenario/removeBackground`, { imageUrl: bae64 });
        return response.data;
    }

    static async uploadToIpfs(blob: string) {
        const projectId = '2NdmlrVjwWmbs7F3AsrupXJMqZR';
        const projectSecret = '15521233f5589edb2cd4b8fc9ada01fc';
        const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
        const client = create({
            host: 'ipfs.infura.io',
            port: 5001,
            protocol: 'https',
            apiPath: '/api/v0',
            headers: {
                authorization: auth,
            }
        })

        const res = await client.add(blob)
        return res?.cid;
    }

}
