import React, { useEffect } from "react";
import styled from "styled-components";
import { InferenceParameters } from "./scenarioGG/models/inference-parameters.model";

interface Props {
  parameters: InferenceParameters;
  onParameterChange: (parameters: InferenceParameters) => void;
  close: () => void;
}

const ParametersPanel: React.FC<Props> = ({ parameters, onParameterChange, close }) => {
  const [params, setParams] = React.useState<InferenceParameters>(parameters);

  const handlePromptChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setParams({
      ...params,
      prompt: event.target.value,
    });
  };

  const handleNegPromptChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setParams({
      ...params,
      negativePrompt: event.target.value,
    });
  };


  const handleSliderChange = (key: keyof InferenceParameters) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setParams({
      ...params,
      [key]: parseFloat(event.target.value),
    });
  };

  const handleSizeChange = (key: keyof InferenceParameters) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setParams({
      ...params,
      [key]: parseInt(event.target.value),
    });
  };

  const handleSelectChange = (key: keyof InferenceParameters) => (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setParams({
      ...params,
      [key]: event.target.value,
    });
  };

  return (
    <div className="modal-ext">
      <div className="card card-register p-4 text-white">
        <div className="card pb-4" style={{
          maxHeight: "calc(100svh - 100px)",
          overflowY: "auto"
        }}>
          <small>Base prompt:</small>
          <TextArea defaultValue={params.prompt} onChange={handlePromptChange} />

          <small>Strength:</small>
          <SliderWrapper>
            <Slider
              type="range"
              min="0"
              max="1"
              step="0.01"
              defaultValue={params.strength || 0}
              onChange={handleSliderChange("strength")}
            />
            <SliderValue>{params.strength?.toFixed(2)}</SliderValue>
          </SliderWrapper>

          <small>Guidance:</small>
          <SliderWrapper>
            <Slider
              type="range"
              min="0"
              max="20"
              step="1"
              defaultValue={params.guidance || 0}
              onChange={handleSliderChange("guidance")}
            />
            <SliderValue>{params.guidance?.toFixed(0)}</SliderValue>
          </SliderWrapper>

          <small>Steps:</small>
          <Input
            type="number"
            min="1"
            max="100"
            defaultValue={params.numInferenceSteps}
            onChange={handleSizeChange("numInferenceSteps")}
          />

          <small>Width:</small>
          <Input
            type="number"
            min="1"
            max="2048"
            defaultValue={params.width}
            onChange={handleSizeChange("width")}
          />

          <small>Height:</small>
          <Input
            type="number"
            min="1"
            max="2048"
            defaultValue={params.height}
            onChange={handleSizeChange("height")}
          />

          <small>Modality:</small>
          <Select
            value={params.modality}
            onChange={handleSelectChange("modality")}
          >
            <option value="">None</option>
            <option value="character">Character</option>
            <option value="landscape">Landscape</option>
            <option value="city">City</option>
          </Select>

          <small>Type:</small>
          <Select
            value={params.type}
            onChange={handleSelectChange("type")}
          >
            <option value="img2img">Img2img</option>
            <option value="controlnet">Controlnet</option>
            <option value="inpaint">Inpaint</option>
          </Select>

          <small>Negative prompt:</small>
          <TextArea defaultValue={params.negativePrompt} onChange={handleNegPromptChange} />
        </div>
        <div className="d-flex justify-content-center">
          <button className="btn btn-secondary mr-2" onClick={() => close()}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={() => { onParameterChange(params); close(); }}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

const Input = styled.input`
  font-size: 1.2rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: -0.25rem;
`;

const TextArea = styled.textarea`
  font-size: 1.2rem;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 140px;
  font-size: 15px;
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Slider = styled.input`
  flex-grow: 1;
  margin-right: 10px;
`;

const SliderValue = styled.span`
  font-size: 1.2rem;
  min-width: 3ch;
  text-align: right;
  color: #666;
`;

const Select = styled.select`
  font-size: 1.2rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: -0.25rem;
`;

export default ParametersPanel;
