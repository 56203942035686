import React, { useEffect, useState } from "react";
import { scenarioAPI } from "./App";

type Props = {
    onSetSearched: (obj: { src: string, prompts: string }[]) => void;
    base: string;
};
let lastSearch: any = undefined;
const UpleashSearchPanel: React.FC<Props> = ({ onSetSearched, base }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [isGenerateImage, setisGenerateImage] = useState(true);

    const saveToLocaleStoreAge = (searchTerm: string, result: any) => {
        const searchHistory = localStorage.getItem("searchHistory");
        if (searchHistory) {
            const parsed = JSON.parse(searchHistory);
            parsed[searchTerm] = result;
            localStorage.setItem("searchHistory", JSON.stringify(parsed));
        } else {
            const obj = { [searchTerm]: result };
            localStorage.setItem("searchHistory", JSON.stringify(obj));
        }
    }

    useEffect(() => {
        if (localStorage.getItem("isGenerateImage")) {
            setisGenerateImage(localStorage.getItem("isGenerateImage") ? JSON.parse(localStorage.getItem("isGenerateImage") || "true") : true);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("isGenerateImage", JSON.stringify(isGenerateImage));
    }, [isGenerateImage]);

    const getFromLocaleStorage = (searchTerm: string) => {
        const searchHistory = localStorage.getItem("searchHistory");
        if (searchHistory) {
            const parsed = JSON.parse(searchHistory);
            return parsed[searchTerm];
        }
        return null;
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSearching(true);
        try {
            const search = "Single isometric " + searchTerm + " as game asset , zoomed out, no ground and no background";

            if (isGenerateImage) {
                const imgs = await scenarioAPI.generateImgDale(search);
                const resultMapped = imgs.map((o: any) =>
                    ({ src: o.url, prompts: searchTerm }));

                const gens: any[] = JSON.parse(sessionStorage.getItem("generated") || "[]")
                gens.push(...resultMapped);
                sessionStorage.setItem("generated", JSON.stringify(gens));

                onSetSearched(resultMapped);
                return;
            }

            const historyResult = getFromLocaleStorage(searchTerm);
            if (historyResult) {
                onSetSearched(historyResult);
                setIsSearching(false);
                return;
            }

            const imgs = await scenarioAPI.searchImg(searchTerm);
            const resultMapped = imgs.map((o: any) =>
                ({ src: o, prompts: searchTerm }));
            saveToLocaleStoreAge(searchTerm, resultMapped)
            onSetSearched(resultMapped);
            lastSearch = resultMapped;
        } catch (e) {
            console.error(e);
            alert("Error occured. Please try again.");
        } finally {
            setIsSearching(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex px-2 justify-content-center align-items-center container mb-1">
                <input
                    disabled={isSearching}
                    type="text"
                    style={{ fontSize: "1.1rem" }}
                    className="form-control"
                    placeholder="Knight with a big red sword"
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                />
                <button className="btn btn-info px-4" disabled={isSearching} type="submit">{isSearching && <div className="lds-dual-ring"></div>} {!isSearching && "Go"}</button>
            </div>
            {/* <div className="form-check text-left mt-0">
                <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" id="defaultCheck1" defaultChecked={isGenerateImage} onChange={(e) => { setisGenerateImage(e.target.checked) }} />
                    <span className="form-check-sign"></span>
                    Generate images (DALL-E)
                </label>
            </div> */}
        </form>
    );
};

export default UpleashSearchPanel;