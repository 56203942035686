import axios, { AxiosRequestConfig } from 'axios';
import { ModelResponse } from "./models/model-response.model";
import { InferenceParameters } from "./models/inference-parameters.model";
import { InferenceResponse, InferenceResponseParameters } from "./models/inference-response.model";
import { ScenarioBaseAPI } from "./scenario-base";

export class ScenarioAPI implements ScenarioBaseAPI {
    private readonly BASE_URL = 'https://api.cloud.scenario.gg/v1';
    public API_KEY: string | null = null;

    constructor() {
        this.API_KEY = localStorage.getItem('apiKey') || null;
    }

    hasKey(): boolean {
        return !!this.API_KEY;
    }

    setKey(apiKey: string) {
        this.API_KEY = apiKey;
    }

    private get headers(): AxiosRequestConfig['headers'] {
        return {
            Authorization: "Basic" + this.API_KEY,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    }

    public async getModels(): Promise<ModelResponse[]> {
        const response = await axios.get<ModelResponse[]>(`${this.BASE_URL}/scenario/models`, { headers: this.headers });
        return response.data;
    }

    public async createInferenceText2Image(modelId: string, prompt: string): Promise<InferenceResponse> {
        const url = `${this.BASE_URL}/models/${modelId}/inferences`;
        const data = {
            parameters: {
                type: 'txt2img',
                prompt,
            },
        };
        const response = await axios.post<InferenceResponse>(url, data, { headers: this.headers });
        return response.data;
    }

    public async createInferenceImage2Image(modelId: string, parameters: InferenceParameters | InferenceResponseParameters): Promise<InferenceResponse> {
        const url = `${this.BASE_URL}/models/${modelId}/inferences`;
        const data: any = {
            parameters: {
                ...parameters,
                type: 'img2img',
                numSamples: 2,
                
            },
            modelId
        }

        const response = await axios.post<InferenceResponse>(url, data, { headers: this.headers });
        return response.data;
    }
    public async getInferenceResult(modelId: string, inferenceId: string): Promise<InferenceResponse> {
        const url = `${this.BASE_URL}/models/${modelId}/inferences/${inferenceId}`;
        const response = await axios.get(url, { headers: this.headers });
        return response.data;
    }

}
