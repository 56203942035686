import { generateNew } from './generator-panel';
import { InferenceResponseParameters } from './scenarioGG/models/inference-response.model';


export function getProportions(src: string) {
    return new Promise<{ width: number, height: number }>((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
            const scaledDimensions = getScaledResolutions(img.width, img.height, 512, 512, 512);
            const minRes = getMinRes(scaledDimensions.width, scaledDimensions.height);
            resolve(minRes);
        };
        img.src = src;
    })
}

export function getScaledResolutions(imageWidth: number, imageHeight: number, maxWidthOrHeight: number, browserWidth: number, minWidth = 512): { width: number, height: number } {
    const aspectRatio = imageWidth / imageHeight;
    const maxDimension = Math.min(maxWidthOrHeight, browserWidth - 8); // subtract 32px for padding and borders
    let width = imageWidth;
    let height = imageHeight;

    if (imageWidth > maxDimension || imageHeight > maxDimension) {
        if (aspectRatio > 1) {
            width = maxDimension;
            height = maxDimension / aspectRatio;
        } else {
            height = maxDimension;
            width = maxDimension * aspectRatio;
        }
    } else if (imageWidth < minWidth) {
        width = minWidth;
        height = minWidth / aspectRatio;
    }

    return { width, height };
}

export function getMinRes(imageWidth: number, imageHeight: number, minWidth = 512, forceWidth = false): { width: number, height: number } {
    let width = imageWidth;
    let height = imageHeight;
    const aspectRatio = imageWidth / imageHeight;

    if (imageWidth < minWidth || forceWidth) {
        width = minWidth;
        height = minWidth / aspectRatio;
    }

    return { width, height };
}

export async function resizeImage(src: string): Promise<string> {
    return new Promise((resolve) => {
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        img.onload = () => {

            if (img.width > 312) {
                resolve(src);
                return;
            }

            const scaledDimensions = getScaledResolutions(img.width, img.height, 1023, 21000, 312);
            canvas.width = scaledDimensions.width;
            canvas.height = scaledDimensions.height;
            if (ctx) {
                ctx.drawImage(img, 0, 0, scaledDimensions.width, scaledDimensions.height);
            }

            resolve(canvas.toDataURL());
        };
        img.src = src;
    })
}

export async function urlToBase64(url: string): Promise<string> {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64String = reader.result as string;
            const base64PNG = base64String.replace("data:binary/octet-stream;base64,", "data:image/png;base64,");
            resolve(base64PNG);
        };
    });
}

export async function handleRegenerate(image: string, data: InferenceResponseParameters): Promise<void> {
    const base64Img = await urlToBase64(image)
    const param = {
        ...data.parameters,
        prompt: data.parameters.prompt,
        image: base64Img,
    }

    generateNew({ modelId: data?.modelId, param })
};


export async function getBase64Image(image: string): Promise<string> {
    const proxyUrl = 'https://proxy.cors.sh/';
    const apiKey = 'temp_f9fc8cf697fca198d4e399a3f670b3eb';

    const response = await fetch(proxyUrl + image, {
        headers: {
            'x-cors-api-key': apiKey
        }
    });

    if (!response.ok) {
        throw new Error(`Failed to load image: ${response.statusText}`);
    }

    const blob = await response.blob();
    const str = URL.createObjectURL(blob);
    console.log(str);
    return str;
}

