import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ModelResponse, TrainingImage } from "./scenarioGG/models/model-response.model";
import 'swiper/css';
import UpleashSearchPanel from "./upleash-search.panel";
import * as Icon from 'react-bootstrap-icons';
import { getTemplates } from "./definitions/templatesToStyle";
import DrawCard from "./draw-panel";
import { urlToBase64 } from "./utilts";

interface Props {
    onChangeStrength: (strength: number) => void;
    setPrompt: (prompt: string) => void;
    models: ModelResponse[],
    strength: number | null;
    selectImage: (imageName: ImageResult | null, model: string | undefined, template: { src: string, prompts: string } | null) => void;
    setModel: (model: ModelResponse) => void;
}{ }

export interface ImageResult {
    src: string;
    width: number;
    height: number;
}

interface State {
    selectedModel: ModelResponse | null;
    selectedImage: { src: string, prompts: string } | null;
    models: ModelResponse[];
    strength: number | null;
    finalImage: ImageResult | null;
    prompt: string;
}

export const ImageSelector: React.FC<Props> = ({ models, strength, selectImage, setModel }) => {
    const [state, setState] = useState<State>({
        selectedModel: null,
        selectedImage: null,
        models: [],
        strength: null,
        finalImage: null,
        prompt: "",
    });
    const [isStyleVisible, setIsStyleVisible] = useState(false);
    const [isBaseVisible, setIsBaseVisible] = useState(false);

    useEffect(() => {
        setState({
            ...state,
            models,
            strength,
            selectedModel: state.selectedModel ?? models[0],
        });
    }, [models, strength]);

    const handleImageTypeChange = (model: ModelResponse) => {
        setState({
            ...state,
            selectedModel: model,
        });
        setModel(model);
    };

    const onDrawImageChange = (image: ImageResult) => {
        setState({
            ...state,
            finalImage: image,
        });
    };

    const handleFileInputClick = () => {
        document.getElementById('fileInput')?.click();
    };

    function ImagePicker({ base, handleImageChange }: any) {
        const gens: any[] = sessionStorage.getItem("generated") ? JSON.parse(sessionStorage.getItem("generated") || "") : [];
        const uploads: any[] = sessionStorage.getItem("uploads") ? JSON.parse(sessionStorage.getItem("uploads") || "") : [];

        const [searched, setSearched] = useState([...uploads, ...gens, ...getTemplates(),]);
        const [selected, setSelected] = useState<string | null>(null);

        const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files?.length == null) return;
            const file = URL.createObjectURL(event.target.files[0]);

            let bsase64 = await urlToBase64(file)

            uploads.push({ src: bsase64, prompts: "" });
            sessionStorage.setItem("uploads", JSON.stringify(uploads));
            searched.unshift({
                src: bsase64,
                prompts: ""
            });
            setSearched([...searched]);
        };

        const handleSetSearched = (gens: any[]) => {
            searched.unshift(...gens);
            setSearched([...searched]);
        };

        return <>
            <div className="card p-2">

                <UpleashSearchPanel onSetSearched={handleSetSearched} base={base} />
                <div className="v-unipanel v-special-wrap mt-2">
                    <div
                        className="pointer"
                        style={{ width: "86px", height: "100px", marginRight: "0.25rem" }}
                        onClick={handleFileInputClick}
                    >
                        <div className="btn rounded-lg text-white" style={{ textAlign: "center", lineHeight: "61px" }}>+</div>
                        <input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            onChange={handleFileInputChange}
                            style={{ display: "none" }}
                        />
                    </div>
                    {searched.map((template) => (
                        <div style={{ width: "86px", height: "100px", marginRight: "0.25rem" }} key={template.src}>
                            <ImageCard
                                src={template.src}
                                isSelected={selected === template?.src}
                                onClick={async () => {
                                    let bsase64 = template.src;

                                    if (template.isTmp) {
                                        bsase64 = await urlToBase64(template.src)
                                    }

                                    setSelected(template.src);
                                    handleImageChange({
                                        ...template,
                                        src: bsase64
                                    });
                                }}
                            />
                        </div>
                    ))}


                </div>
            </div>
        </>
    }

    function StyleSelection({ model }: any) {
        const [selectedModel, setSelectedModel] = useState<ModelResponse | null>(model);

        return <>
            <div className="modal-ext">
                <div className="card card-register" style={{ maxWidth: "750px" }}>
                    <div className="closei-btn" onClick={() => setIsStyleVisible(false)}>X</div>
                    <div className="card-body text-center"></div>
                    <div className="card p-2">
                        <div className="v-unipanel v-special-wrap" style={{ overflow: "auto" }}>
                            {models.map((model) => (
                                <div style={{ width: "128px", height: "138px", minWidth: "128px", minHeight: "138px", position: "relative", marginRight: "0.25rem" }} key={model.id}>
                                    <div onClick={() => { setSelectedModel(model) }} className={"card pointer card-picker " + (selectedModel === model ? "selected" : "")}>
                                        <div className="card-body p-2 box-text m-4">
                                            <img src={model.trainingImages[0].downloadUrl} className="card-bg" />
                                            <div className="picker-card-text" style={{ fontSize: "1rem" }}>{model.name}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center pb-2">
                        <button className="btn btn-secondary mr-2" onClick={() => setIsStyleVisible(false)}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={() => {
                            handleImageTypeChange(selectedModel!); setIsStyleVisible(false)

                            selectImage(
                                state.finalImage,
                                selectedModel?.id ?? models[2]?.id,
                                state.selectedImage,
                            );
                        }}>
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </>
    }

    function BaseSelection({ }: any) {
        const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);


        const apply = () => {
            setIsBaseVisible(false);
            setState({
                ...state,
                selectedImage: selectedTemplate,
                finalImage: selectedTemplate,
            });

            selectImage(
                selectedTemplate,
                state.selectedModel?.id ?? models[2]?.id,
                selectedTemplate,
            );
        }

        function handleImageChange(data: any) {
            setSelectedTemplate(data);
        }

        return <>
            <div className="modal-ext">
                <div className="card card-register" style={{ maxWidth: "750px" }}>
                    <div className="closei-btn" onClick={() => setIsBaseVisible(false)}>X</div>
                    <div className="card-body text-center"></div>
                    <ImagePicker key="swaggg" handleImageChange={handleImageChange} />
                    <div className="d-flex justify-content-center pb-2">
                        <button className="btn btn-secondary mr-2" onClick={() => setIsBaseVisible(false)}>
                            Cancel
                        </button>
                        <button className="btn btn-primary" onClick={() => { apply() }}>
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </>

    }

    const drawMemo = React.useMemo(() => {

        if (!state.selectedImage) return <>
            <div onClick={() => setIsBaseVisible(true)}
                className=" p-2  d-flex justify-content-center align-items-center" >
                <Icon.HandIndex className="h1 text-secondary " ></Icon.HandIndex>
            </div>
        </>;

        return <DrawCard image={state.selectedImage.src} imageChanged={onDrawImageChange} />
    }, [state.selectedImage])


    return (
        <>
            <div className=" w-100 justify-content-center align-items-center" style={{ flexWrap: "wrap" }}>
                <div className='text-center mr-md-4 mb-4'>
                    <div className="text-left mt-4" style={{ display: "inline-block" }}>
                        <div>Style <span className="badge badge-default pointer" onClick={() => setIsStyleVisible(true)}>{state.selectedModel?.name}
                            <Icon.Pencil className="ml-2"></Icon.Pencil>
                        </span> </div>
                        {isStyleVisible && <StyleSelection model={state.selectedModel} />}

                        {isBaseVisible && <BaseSelection />}
                    </div>
                </div>

                <div className="position-relative pointer">
                    <Step onClick={() => setIsBaseVisible(true)} className="card p-2 rounded justify-content-center" style={{ minWidth: "315px", minHeight: "300px" }}>
                        {drawMemo}
                    </Step>
                    {/* <div className="card d-flex flex-row text-white justify-content-center mb-0 p-2 pointer mb-4" onClick={() => setIsBaseVisible(true)}>
                        Change <Icon.Pencil className="ml-2"></Icon.Pencil>
                    </div> */}
                </div>
            </div>
        </>
    );
};


const Step = styled.div`
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0px;
            `;


const Card = styled.div<{ isSelected: boolean }>`
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
            `;

const ImageCard: React.FC<any> = ({
    src,
    isSelected,
    onClick,
}) => {

    if (!src) {
        return null;
    }

    return (
        <Card
            onClick={() => onClick(src)}
            isSelected={isSelected}
            className={"pointer card card-picker " + (isSelected ? "selected" : "")}
            data-testid="model-card"
        >
            <div className="card-body p-2 box-text m-4">
                <img src={src} className="card-bg" style={{ opacity: 1 }} />
            </div>
        </Card>
    );
};


