import React, { useState } from "react";
import styled from "styled-components";
import { InferenceResponseParameters } from "./scenarioGG/models/inference-response.model";
import * as Icon from 'react-bootstrap-icons';
import { scenarioAPI } from "./App";

interface Props {
  image: { url: string; id: string };
  onRegenerate: () => void;
  footerVisible: boolean;
  data: InferenceResponseParameters;
}

const ResultImagePanel: React.FC<Props> = ({
  footerVisible,
  image,
  onRegenerate,
  data,
}) => {

  const [zoomedImage, setZoomedImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleZoom = () => {
    setZoomedImage(image.url)
  };

  const handleSave = (imageData: any) => {
    const link = document.createElement("a");
    link.href = imageData;
    link.download = "generated-image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImageClick = async () => {
    try {
      setIsLoading(true);
      const img = await scenarioAPI.doRemoveBg(image.url);
      const isIframe = window.location !== window.parent.location;

      if (isIframe) {
        window.parent.postMessage({
          type: 'image',
          url: img
        }, '*');
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setIsLoading(false);
        }, 4000);
      } else {
        handleSave(img as any)
      }
    } catch (e) {
      console.log(e)
      setIsLoading(false);
    } finally {
    }
  };

  return (
    <Container>
      <Image
        loading="lazy"
        src={image.url}
        className="pointer"
        alt="result"
        title={data?.parameters?.prompt}
        onClick={handleImageClick}
      />
      {footerVisible && (
        <ButtonContainer>
          <Button className="text-white" onClick={onRegenerate}>Re-generate</Button> |
          <Icon.ZoomIn className="h4 text-white pointer mb-0 ml-4" onClick={handleZoom}></Icon.ZoomIn>
        </ButtonContainer>
      )}
      {
        isLoading && <div className="modal-ext">
          <div className="card card-register" style={{ maxWidth: "750px" }}>
            <div className="card-body text-center d-flex justify-content-center align-items-center">
              {!success && <h1 className="mb-0"> Processing...</h1>}
              {success && <h1 className="mb-0" style={{ color: "#0cbf0c" }}> Successfull added!</h1>}
            </div>
          </div>
        </div>
      }
      {
        zoomedImage && (
          <div className="modal-ext">
            <div className="card card-register" style={{ maxWidth: "750px" }}>
              <div className="closei-btn" onClick={() => setZoomedImage(null)}>X</div>
              <div className="card-body text-center">
                <img src={zoomedImage} className="img-fluid" />
              </div>
              <div className="d-flex justify-content-center pb-2">
                <button className="btn btn-secondary mr-2" onClick={() => setZoomedImage(null)}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        )
      }
    </Container >
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  max-height: 500px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

interface ButtonProps {
  primary?: boolean;
}

const Button = styled.button<ButtonProps>`
  padding: 5px 10px;
  color:white;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ primary }) => (primary ? "#fff" : "#333")};
  background-color: ${({ primary }) =>
    primary ? "#007bff" : "transparent"};
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  &:hover {
    background-color: ${({ primary }) =>
    primary ? "#0069d9" : "#f2f2f2"};
    color: ${({ primary }) => (primary ? "#fff" : "#333")};
  }

  &:focus {
    outline: none;
  }
`;

export default ResultImagePanel;
