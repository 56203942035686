export class Generations {
    private generations: any[] = [];

    public get(): any[] {
        return this.generations;
    }

    public getFirst(): any {
        return this.generations[0];
    }

    public addGeneration(generation: any): void {
        generation.id = this.generations.length + Date.now();
        this.generations.unshift(generation);
    }

    public removeGeneration(genId: any): void {
        this.generations = this.generations.filter((generation) => generation.id != genId);
    }
}