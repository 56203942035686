import { Step } from './../App';

export const templates = {
    "isometricCharacters": [
        { src: "/templates/character/t1.png", prompts: "random character with random clothes" },
        { src: "/templates/character/t2.png", prompts: "random character with random clothes" },
        { src: "/templates/character/t3.png", prompts: "random character with random clothes" },

        // { src: "/templates/character/iso/cheer_iso.png", prompts: "cheering pose, arms in the air, detailed cheering face, detailed  body, detailed legs, detailed feet" },
        // { src: "/templates/character/iso/jump_iso.png", prompts: "happy jump pose, arms in the air, feets in the air, detailed happy face, detailed body, detailed legs, detailed feet" },
        // { src: "/templates/character/iso/sad_iso.png", prompts: "pose with head is looking down and arms hanging, detailed sad face, detailed body, detailed legs, detailed feet" },
        // { src: "/templates/character/iso/shocked_iso.png", prompts: " hands on the mouth, detailed frightened face expression, detailed body, detailed legs, detailed feet" },
        // { src: "/templates/character/iso/walk_iso.png", prompts: "walking pose, detailed face, detailed body, detailed legs, detailed feet" },
    ],
    "sideScrollingCharacters": [
        { src: "/templates/character/sidescroll/base1_side.png", prompts: "detailed face, detailed body, detailed legs, detailed feet" },
    ],
    "floor": [
        // { src: "/templates/floor/Water.jpeg", prompts: "water" },
        // { src: "/templates/floor/WoodFloor.jpg", prompts: "wooden floor" },
        // { src: "/templates/floor/WoodPlankSmall.jpeg", prompts: "wooden floor" }
    ],
    "room": [
        // { src: "/templates/room/Desk.png", prompts: "object is a desk" },
        // { src: "/templates/room/FlatTv.png", prompts: "object is a flat TV" },
    ],
    "blank": [
        { src: "/templates/blank.png", prompts: "" },
    ],
    "outside": [
        { src: "/templates/outside/house_side.png", prompts: "object is a house, front view" },
        { src: "/templates/outside/tree_side.png", prompts: "object is a big curly tree, front view" },
    ]
};

export function getTemplates(): { src: string, prompts: string }[] {
    const result = [];

    result.push(...templates.isometricCharacters);
    result.push(...templates.room);

    const origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

    return result.map((template) => ({
        src: origin + template.src,
        prompts: template.prompts,
        isTmp: true
    }));
}